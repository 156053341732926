import { useAppContext } from '../AppContext//App.context';
import useAxiosInterceptor from '../utils/project-axios.instance';

interface IDataObject {
	url: string;
	data: object;
	headers?: object;
	API_BASE_URL?: string;
	accessTokenInHeaders?: boolean;
}
import { useLocation } from 'react-router-dom';
const useApiService = () => {
	const [appData]: any = useAppContext();
	const { userData } = appData;
	let API_BASE_URL = process.env.REACT_APP_API_URL;
	const API_BASE_SNOWFLAKE_URL = 'https://warehouse-docs.diggrowth.com/';
	const API_BASE_URL_DOMAIN = process.env.REACT_APP_API_URL_DOMAIN;
	const axiosInstance = useAxiosInterceptor();
	const access_token = userData['access-token'];
	const location = useLocation();
	const { pathname, search } = location;

	const splitArry = pathname;
	// console.log('pathname', pathname);
	const latPathName = splitArry;
	// console.log("pathname", latPathName);
	const report_name = latPathName || '';

	return {
		// ========= get ==============
		get(obj: IDataObject, accessTokenInHeaders?: boolean, snowflake?: boolean) {
			if (obj.API_BASE_URL) {
				API_BASE_URL = obj.API_BASE_URL;
			}
			if (accessTokenInHeaders)
				return axiosInstance.get(
					snowflake ? API_BASE_SNOWFLAKE_URL + obj.url : API_BASE_URL + obj.url,
					{
						params: {
							...obj.data,
						},
						headers: {
							'access-token': access_token,
							...obj.headers,
							'Report-Name': report_name,
						},
					}
				);
			return axiosInstance.get(
				snowflake ? API_BASE_SNOWFLAKE_URL + obj.url : API_BASE_URL + obj.url,
				{
					params: {
						...obj.data,
					},
					headers: {
						...obj.headers,
						'Report-Name': report_name,
						'access-control-allow-credentials': 'true',
						'access-control-allow-headers':
							'X-Requested-With,content-type,access-token,api_key,Report-Name',
						'access-control-allow-methods': 'GET, POST, OPTIONS, PUT, DELETE',
						'access-control-allow-origin': '*',
					},
				}
			);
		},
		// ========= post ==============
		post(
			obj: IDataObject,
			accessTokenInHeaders?: boolean,
			snowflake?: boolean
		) {
			if (obj.API_BASE_URL) {
				API_BASE_URL = obj.API_BASE_URL;
			}
			if (accessTokenInHeaders)
				return axiosInstance.post(
					snowflake ? API_BASE_SNOWFLAKE_URL + obj.url : API_BASE_URL + obj.url,
					{
						...obj.data,
					},
					{
						headers: {
							'access-token': access_token,
							...obj.headers,
							'Report-Name': report_name,
						},
					}
				);
			return axiosInstance.post(
				snowflake ? API_BASE_SNOWFLAKE_URL + obj.url : API_BASE_URL + obj.url,
				{
					...obj.data,
				},
				{ headers: { ...obj.headers, 'Report-Name': report_name } }
			);
		},
		// ========= put ==============
		put(obj: IDataObject, accessTokenInHeaders?: boolean, snowflake?: boolean) {
			if (obj.API_BASE_URL) {
				API_BASE_URL = obj.API_BASE_URL;
			}
			if (accessTokenInHeaders)
				return axiosInstance.put(
					snowflake ? API_BASE_SNOWFLAKE_URL + obj.url : API_BASE_URL + obj.url,
					{
						...obj.data,
					},
					{
						headers: {
							'access-token': access_token,
							...obj.headers,
						},
					}
				);
			return axiosInstance.put(
				snowflake ? API_BASE_SNOWFLAKE_URL + obj.url : API_BASE_URL + obj.url,
				{
					...obj.data,
				},
				{ headers: { ...obj.headers } }
			);
		},
		// ============ delete =================
		delete(
			obj: IDataObject,
			accessTokenInHeaders?: boolean,
			snowflake?: boolean
		) {
			if (obj.API_BASE_URL) {
				API_BASE_URL = obj.API_BASE_URL;
			}
			if (accessTokenInHeaders)
				return axiosInstance.delete(
					snowflake ? API_BASE_SNOWFLAKE_URL + obj.url : API_BASE_URL + obj.url,
					{
						headers: { 'access-token': access_token, ...obj.headers },
						data: {
							...obj.data,
						},
					}
				);
			return axiosInstance.delete(
				snowflake ? API_BASE_SNOWFLAKE_URL + obj.url : API_BASE_URL + obj.url,
				{
					headers: { ...obj.headers },
					data: {
						...obj.data,
					},
				}
			);
		},
		getDomain(obj: IDataObject) {
			return axiosInstance.get(API_BASE_URL_DOMAIN + obj.url, {
				params: {
					...obj.data,
				},
			});
		},
		postDomain(obj: IDataObject) {
			return axiosInstance.post(API_BASE_URL_DOMAIN + obj.url, {
				...obj.data,
			});
		},
		postFormData(obj: IDataObject, accessTokenInHeaders?: boolean) {
			if (accessTokenInHeaders) {
				return axiosInstance.post(API_BASE_URL + obj.url, obj.data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'access-token': access_token,
						...obj.headers,
					},
				});
			} else {
				return axiosInstance.post(API_BASE_URL + obj.url, obj.data, {
					headers: { 'Content-Type': 'multipart/form-data', ...obj.headers },
				});
			}
		},
		putFormData(obj: IDataObject, accessTokenInHeaders?: boolean) {
			if (accessTokenInHeaders) {
				return axiosInstance.put(API_BASE_URL + obj.url, obj.data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'access-token': access_token,
						...obj.headers,
					},
				});
			} else {
				return axiosInstance.put(API_BASE_URL + obj.url, obj.data, {
					headers: { 'Content-Type': 'multipart/form-data', ...obj.headers },
				});
			}
		},
	};
};

export default useApiService;
